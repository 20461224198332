import React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from 'common/src/theme/cryptoModern';
import { ResetCSS } from 'common/src/assets/css/style';
import Sticky from 'react-stickynode';
import Navbar from '../containers/Neua/Navbar';
import Banner from '../containers/Neua/Banner';
import Timeline from '../containers/Neua/TimelineSection'
import FaqSection from '../containers/Neua/FaqSection';
import ContactButton from '../containers/Neua/TrialSection';
import Services from '../containers/Neua/Services';
import Footer from '../containers/Neua/Footer';
import GlobalStyle, {
  CryptoWrapper,
  ContentWrapper,
} from '../containers/Neua/cryptoModern.style';

import SEO from '../components/seo';

export default function () {
  return (
    <ThemeProvider theme={theme}>
      <>
        <SEO title="Neua Software" />

        <ResetCSS />
        <GlobalStyle />

        <CryptoWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-active">
            <Navbar />
          </Sticky>
          <ContentWrapper>
            <Banner />
            <Timeline />
            <Services />
            <FaqSection />
            <ContactButton />
          </ContentWrapper>
          <Footer />
        </CryptoWrapper>
      </>
    </ThemeProvider>
  );
}
