import React from 'react';
import Fade from 'react-reveal/Fade';
import { Link } from 'gatsby';
import { Icon } from 'react-icons-kit';
import { facebook } from 'react-icons-kit/fa/facebook';
import { linkedinSquare } from 'react-icons-kit/fa/linkedinSquare'
import { instagram } from 'react-icons-kit/fa/instagram'
import Text from 'reusecore/src/elements/Text';
import Image from 'reusecore/src/elements/Image';
import Button from 'reusecore/src/elements/Button';
import Heading from 'reusecore/src/elements/Heading';
import Rating from 'common/src/components/Rating';
import Container from 'common/src/components/UI/Container';
import BannerWrapper, {
  BannerContent,
  RatingInfo,
  BannerImage,
  ButtonGroup,
} from './banner.style';

import microsoft from 'common/src/assets/image/appClassic/microsoft.svg';
import bannerImg from 'common/src/assets/image/neua/banner-isometric2.png';

const Banner = () => {
  return (
    <BannerWrapper id="home">
      <Container>
        <BannerContent>
          <Fade up delay={100}>
            <Heading
              as="h1"
              content="A team of web developers for hire"
            />
          </Fade>
          {/* <Fade up delay={300}> */}
          <div className='css-animation'>
            <Text
              content={`Design. Development. Deployment.`}
            />
          </div>
          {/* </Fade> */}
          <Fade up delay={300}>
            <ButtonGroup>
              {/* <Link to={'#services'}>
                <Button className="primary" title="Services" />
              </Link> */}
              <Button
                className="text"
                variant="textButton"
                icon={<Icon icon={facebook} />}
                iconPosition="left"
                onClick={() => window.open('https://www.facebook.com/neuasoftware/')}
              />
              <Button
                className="text"
                variant="textButton"
                icon={<Icon icon={linkedinSquare} />}
                iconPosition="left"
                onClick={() => window.open('https://www.linkedin.com/company/neuasoftware')}
              />
              <Button
                className="text"
                variant="textButton"
                icon={<Icon icon={instagram} />}
                iconPosition="left"
                onClick={() => window.open('https://www.instagram.com/neua.au/')}
              />

            </ButtonGroup>
          </Fade>
        </BannerContent>
        <BannerImage>
          <Fade up delay={100}>
            <Image src={bannerImg} alt="Banner" />
          </Fade>
        </BannerImage>
      </Container>
    </BannerWrapper>
  );
};

export default Banner;
