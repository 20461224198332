import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Input from 'reusecore/src/elements/Input';
import CheckBox from 'reusecore/src/elements/Checkbox/index';
import Button from 'reusecore/src/elements/Button';
import Image from 'reusecore/src/elements/Image';
import LoginModalWrapper from './loginModal.style';
import 'rc-tabs/assets/index.css';
import LogoImage from 'common/src/assets/image/agency/logo.png';
import LoginImage from 'common/src/assets/image/neua/contact-form-image.jpg';
import GoogleLogo from 'common/src/assets/image/agency/google-icon.jpg';

const LoginModal = ({
  row,
  col,
  btnStyle,
  logoStyle,
  titleStyle,
  contentWrapper,
  inputStyle,
  outlineBtnStyle,
  descriptionStyle,
  googleButtonStyle,
}) => {

  return (
    <LoginModalWrapper>
      <Box className="row" {...row}>
        <Box className="col imageCol" {...col}>
          <Image className="patternImage" src={LoginImage} alt="Login Banner" />
        </Box>
        <Box className="col tabCol" {...col}>
          <Box {...contentWrapper}>
            {/* <Image src={LogoImage} {...logoStyle} alt="Logo" /> */}
            {/* <Tabs
              defaultActiveKey="loginForm"
              renderTabBar={() => <ScrollableInkTabBar />}
              renderTabContent={() => <TabContent />}
            > */}
            {/* <TabPane tab="PROJECT" key="loginForm"> */}
            <Heading content="Contact Us" {...titleStyle} />
            {/* <Text
              content="Welcome to Mate Family. Please login with your personal account information letter."
              {...descriptionStyle}
            /> */}
            <Input inputType="text" isMaterial label="Name" />
            <br />
            <Input inputType="email" isMaterial label="Email" />
            <br />
            <Input inputType="number" isMaterial label="Phone" />
            <br />
            <Input inputType="textarea" isMaterial label="Message / Enquiry" />

            <Button className="default" title="SEND" {...btnStyle} />
          </Box>
        </Box>
      </Box>
    </LoginModalWrapper>
  );
};

// LoginModal style props
LoginModal.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  logoStyle: PropTypes.object,
  titleStyle: PropTypes.object,
  hintTextStyle: PropTypes.object,
  contentWrapper: PropTypes.object,
  descriptionStyle: PropTypes.object,
  googleButtonStyle: PropTypes.object,
};

// LoginModal default style
LoginModal.defaultProps = {
  // Team member row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    maxWidth: '800px',
    pr: ['15px', '30px', '30px', '30px'],
    pl: ['15px', '30px', '0px', '0px']
  },
  // Team member col default style
  col: {
    width: [1, 1 / 2],
  },
  // Default logo size
  logoStyle: {
    width: '128px',
    height: 'auto',
    ml: '15px',
  },
  // Title default style
  titleStyle: {
    textAlign: 'center',
    fontSize: ['22px', '36px', '50px'],
    fontWeight: '400',
    color: '#20201D',
    letterSpacing: '-0.025em',
    mt: '25px',
    mb: '10px',
  },
  // Description default style
  descriptionStyle: {
    color: 'rgba(52, 61, 72, 0.8)',
    fontSize: '15px',
    lineHeight: '26px',
    letterSpacing: '-0.025em',
    mb: '23px',
    ml: '1px',
  },
  // Content wrapper style
  contentWrapper: {
    // pt: ['5px', '10px'],
    pl: ['10px', '32px', '38px', '40px', '56px'],
    pr: '10px',
    // pb: ['32px', '56px'],
  },
  // Default button style
  inputStyle: {
    mb: '30px',
  },
  btnStyle: {
    display: 'block',
    m: 'auto',
    mt: '15px',
    mb: '40px',
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
  },
  // Outline button outline style
  outlineBtnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#10ac84',
  },
  // Google button style
  googleButtonStyle: {
    bg: '#ffffff',
    color: '#343D48',
  },
};

export default LoginModal;
