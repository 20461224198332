import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Container from 'common/src/components/UI/Container';
import {
  Accordion,
  AccordionItem,
  AccordionTitle,
  AccordionBody,
  IconWrapper,
  OpenIcon,
  CloseIcon,
} from 'common/src/components/Accordion';
import { Icon } from 'react-icons-kit';
import { thinDown } from 'react-icons-kit/entypo/thinDown';
import { thinRight } from 'react-icons-kit/entypo/thinRight';
import FaqSectionWrapper from './faqSection.style';
const FaqSection = ({
  sectionHeader,
  sectionTitle,
  sectionSubTitle,
  subtitle,
  titleStyle,
  descriptionStyle,
}) => {
  const Data = [
    {
      "id": 1,
      "expend": true,
      "title": "Can you build integrated applications and systems for my business?",
      "description": "Yes, we are able to build into your current systems, contact us via email to organise a discussion about the project requirements, software stack and required timeframes and we'll let you know how we would approach the solution."
    },
    {
      "id": 2,
      "title": "Can you build me the next big app?",
      "description": "There are several types of applications that we can build including desktop applications, web-based applications (PWA's) and mobile applications, we can develop all of the above, however solution specifics are required to accurately form an idea of the complexity of your application."
    },
    {
      "id": 3,
      "title": "What do you use to build websites and web applications?",
      "description": "We develop our websites typically using React - a framework provides a programmable layer and renders to the web languages (HTML, CSS, JS). This allows for powerful customisation, complex and secure API connectivity and faster websites. When paired with software such as GraphQL, GatsbyJS and Serverless infrastructure the possibilites are endless."
    },
    {
      "id": 4,
      "title": "Can you integrate with a CMS so I can add content without having to code?",
      "description": "Yes, when we build a website or web application we can integrate a CMS and/or create ways for you to publish content to your site without needing to write any code."
    }
  ]

  return (
    <FaqSectionWrapper id="faqSection">
      <Container>
        <Box {...sectionHeader} className="sectionHeader">
          <Text content="FAQ" {...subtitle} />
          <Text content="Frequently Asked Questions" {...sectionSubTitle} />
          {/* <Heading
            content="Here's some extra information about us, based on customer queries"
            {...sectionTitle}
          /> */}
        </Box>
        <Box className="row">
          <Accordion>
            <Fragment>
              {Data.map((faqItem, index) => (
                <AccordionItem key={`accordion_key-${index}`}>
                  <Fragment>
                    <AccordionTitle>
                      <Fragment>
                        <Heading content={faqItem.title} {...titleStyle} />
                        <IconWrapper>
                          <OpenIcon>
                            <Icon icon={thinRight} size={18} />
                          </OpenIcon>
                          <CloseIcon>
                            <Icon icon={thinDown} size={18} />
                          </CloseIcon>
                        </IconWrapper>
                      </Fragment>
                    </AccordionTitle>
                    <AccordionBody>
                      <Text
                        content={faqItem.description}
                        {...descriptionStyle}
                      />
                    </AccordionBody>
                  </Fragment>
                </AccordionItem>
              ))}
            </Fragment>
          </Accordion>
        </Box>
      </Container>
    </FaqSectionWrapper>
  );
};

// FaqSection style props
FaqSection.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
};

// FaqSection default style
FaqSection.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: ['40px', '56px'],
  },
  // sub section default style
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: ['26px', '28px'],
    fontWeight: '700',
    letterSpacing: '-0.025em',
    color: '#fff',
    mb: '15px',
  },
  subtitle: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: 'rgb(255, 227, 105)',
    mb: '10px',
  },
  // section title default style
  sectionTitle: {
    textAlign: 'center',
    fontSize: ['14px', '16px'],
    fontWeight: '400',
    color: '#e3a917',
    mb: '0',
    maxWidth: '420px',
    lineHeight: '1.5',
  },
  // accordion title default style
  titleStyle: {
    fontSize: ['16px', '17px'],
    fontWeight: '400',
    color: '#14161A',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  // accordion description default style
  descriptionStyle: {
    fontSize: '15px',
    color: '#595959',
    lineHeight: '1.75',
    mb: '0',
  },
};

export default FaqSection;
