import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import FeatureSectionWrapper from './featureSection.style';

import SvgComponent from '../../../components/SvgComponent';
import Image1 from '../../../images/051-network.svg'
import Image2 from '../../../images/051-app.svg'
import Image3 from '../../../images/051-api.svg'
import Image4 from '../../../images/051-website.svg'
import Image5 from '../../../images/051-computer-1.svg'
import Image6 from '../../../images/051-strategy.svg'

const FeatureSection = ({
  row,
  col,
  sectionHeader,
  sectionTitle,
  sectionSubTitle,
  featureTitle,
  featureDescription,
  iconStyle,
  contentStyle,
  blockWrapperStyle,
}) => {
  const images = [Image1, Image2, Image3, Image4, Image5, Image6]
  const Data = [
    {
      "id": 1,
      "icon": "web-dev",
      "title": "Web Development",
      "description": "We build custom software for the web including web applications and business websites with React JS and Node."
    },
    {
      "id": 2,
      "icon": "brand",
      "title": "Ui/UX/Brand Design",
      "description": "We can create a style guide for online elements to modernised and beautify your brand."
    },
    {
      "id": 3,
      "icon": "saas",
      "title": "Saas Integration",
      "description": "We can build software to optimise and integrate aspects of your current systems."
    },
    {
      "id": 4,
      "icon": "mobile",
      "title": "Mobile Apps",
      "description": "Building applications for mobile and tablets which port to Android and iOS."
    },
    {
      "id": 5,
      "icon": "desktop",
      "title": "Desktop Apps",
      "description": "Build application solutions for computers and management systems."
    },
    {
      "id": 6,
      "icon": "game",
      "title": "Game Development",
      "description": "We have experience with game design, development and deployment."
    }
  ]

  return (
    <FeatureSectionWrapper id="services">
      <Container>
        <Box {...sectionHeader}>
          <Text content="SERVICES" {...sectionSubTitle} />
          <Heading
            content="Our Expertise"
            {...sectionTitle}
          />
        </Box>
        <Box className="row" {...row}>
          {Data.map((feature, index) => (
            <Box className="col" {...col} key={`feature-${index}`}>
              <FeatureBlock
                icon={<SvgComponent
                  icon={feature.icon}
                  width="110"
                  height="110"

                />}
                wrapperStyle={blockWrapperStyle}
                iconStyle={iconStyle}
                contentStyle={contentStyle}
                title={<Heading content={feature.title} {...featureTitle} />}
                description={
                  <Text content={feature.description} {...featureDescription} />
                }
              />
            </Box>
          ))}
        </Box>
      </Container>
    </FeatureSectionWrapper>
  );
};

// FeatureSection style props
FeatureSection.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  featureTitle: PropTypes.object,
  featureDescription: PropTypes.object,
};

// FeatureSection default style
FeatureSection.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: ['40px', '56px'],
  },
  // sub section default style
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: 'rgb(156, 55, 0)',
    mb: '10px',
  },
  // section title default style
  sectionTitle: {
    textAlign: 'center',
    fontSize: ['26px', '28px'],
    fontWeight: '700',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  // feature row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
  },
  // feature col default style
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 3],
    borderLeft: '1px solid #f1f4f6',
    borderBottom: '1px solid #f1f4f6',
  },
  // feature block wrapper default style
  blockWrapperStyle: {
    p: ['30px', '20px', '30px', '40px'],
  },
  // feature icon default style
  iconStyle: {
    width: '84px',
    height: '84px',
    m: '0 auto',
    borderRadius: '50%',
    bg: '#93d26e',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '36px',
    color: '#ffffff',
    overflow: 'hidden',
    mb: '30px',
  },
  // feature content default style
  contentStyle: {
    textAlign: 'center',
  },
  // feature title default style
  featureTitle: {
    fontSize: ['18px', '20px'],
    fontWeight: '400',
    color: '#0f2137',
    lineHeight: '1.5',
    mb: ['10px', '10px', '10px', '20px'],
    letterSpacing: '-0.020em',
  },
  // feature description default style
  featureDescription: {
    fontSize: '15px',
    lineHeight: '1.75',
    color: '#343d48cc',
  },
};

export default FeatureSection;
